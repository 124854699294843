import "./topbar.scss"
import { AcUnit, Person, Mail } from '@material-ui/icons';

function Topbar({menuOpen, setMenuOpen}) {
    return (
        <div className={'topbar ' + (menuOpen && "active")}>
            <div className="wrapper">
                <div className="left">
                    <a href="#intro" className="logo">Snowcones.io</a>                  
                    <div className="itemContainer">
                        <Person />
                        <span>205 555 5555</span>
                    </div>
                    <div className="itemContainer">
                        <Mail />
                        <span>dev@snowcones.io</span>
                    </div>
                </div>
                <div className="right">
                    <div className="hamburger" onClick={()=>{setMenuOpen(!menuOpen)}}>
                        <span className="line1"></span>
                        <span className="line2"></span>
                        <span className="line3"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topbar
